<lib-menu-global />
<lib-navigation />
<lib-curved-box curve="bottom" [dontHideOverflow]="true">
  <div class="max-w-3xl mx-auto py-10 text-center">
    <h1 class="text-6xl">{{ ['support', 'title'] | translate }}</h1>
    <h2 class="text-md">
      {{ ['support', 'sub_title'] | translate }}
    </h2>
    <app-search></app-search>
  </div>
</lib-curved-box>
<main class="container my-6 px-6 xl:px-56">
  <router-outlet />
</main>
<lib-footer />
