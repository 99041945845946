import { inject, Injectable } from '@angular/core';
import { Slice } from '@prismicio/client';
import { SliceClass } from 'interfaces';
import { LanguageService } from 'language';
import {
  CmsService,
  FooterDocumentDataBodyBottomLinksAreaSlice,
  FooterDocumentDataBodyBottomLinksAreaSliceItem,
  FooterDocumentDataBodyCtaAreaSlice,
  FooterDocumentDataBodyCtaAreaSliceItem,
  FooterDocumentDataBodyLinksAreaSlice,
  FooterDocumentDataBodyLinksAreaSliceItem,
  prismicToHtmlString,
} from 'prismic';
import { CachedDataLoaderService } from 'utils';
import {
  Footer,
  FooterBottomLinksAreaSlice,
  FooterBottomLinkSelection,
  FooterCTAAreaSlice,
  FooterLink,
  FooterLinkItem,
  FooterLinksAreaSlice,
  FooterLinkSection,
} from './footer.class';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  cms = inject(CmsService);
  cachedDataLoader = inject(CachedDataLoaderService);
  lang = inject(LanguageService);

  async getFooter(): Promise<Footer> {
    return (await this.getAll()).footer;
  }

  private async getAll() {
    return await this.cachedDataLoader.get('footer_' + this.lang.current, () => this.loadFooterItems());
  }

  private async loadFooterItems() {
    const resp = await this.cms.getByType('footer');
    const result = resp?.[0]?.data as { body: FooterDocumentDataBodyLinksAreaSlice[] };

    return {
      footer: await this.convertFooter(result.body),
    };
  }

  async convertFooter(prismicData: FooterDocumentDataBodyLinksAreaSlice[]): Promise<Footer> {
    // Temporary workaround. Not the prettiest solution but make it work for now
    const footerLinkSections: Array<FooterLinkSection> = [];
    for (const d of prismicData) {
      if (d.slice_type === 'links_area') {
        footerLinkSections.push(
          new FooterLinkSection(
            d.primary.title,
            d.items.map((l: FooterDocumentDataBodyLinksAreaSliceItem) => new FooterLink(l.link_title, l.link))
          )
        );
      }
    }

    const slices: SliceClass[] = await Promise.all(
      prismicData
        .map(
          (
            sliceData:
              | FooterDocumentDataBodyLinksAreaSlice
              | FooterDocumentDataBodyCtaAreaSlice
              | FooterDocumentDataBodyBottomLinksAreaSlice
          ) => {
            let footerBottomLinkSelections: Array<FooterBottomLinkSelection>;

            switch (sliceData.slice_type) {
              case 'cta_area':
                return new FooterCTAAreaSlice(
                  sliceData as FooterDocumentDataBodyCtaAreaSlice,
                  sliceData.items.map(
                    (i: FooterDocumentDataBodyCtaAreaSliceItem) =>
                      new FooterLinkItem(
                        prismicToHtmlString(i.title),
                        prismicToHtmlString(i.description),
                        i.icon,
                        i.link
                      )
                  )
                );
              case 'links_area':
                // Do nothing here as we build the links_area slice manually
                //   return new FooterLinksAreaSlice({slice_type: 'links_area', label: null}, footerLinkSections);
                break;
              case 'bottom_links_area':
                footerBottomLinkSelections = [];
                footerBottomLinkSelections.push(
                  new FooterBottomLinkSelection(
                    sliceData.items.map(
                      (l: FooterDocumentDataBodyBottomLinksAreaSliceItem) => new FooterLink(l.link_name, l.link)
                    )
                  )
                );
                return new FooterBottomLinksAreaSlice(sliceData, footerBottomLinkSelections);
              default:
                console.error('Slice type not configured', (sliceData as Slice).slice_type);
            }
          }
        )
        .filter((s: SliceClass) => s)
    );

    // Need to clean up the links area. Insert where they should have been
    const linksAreaIdx = prismicData.findIndex(s => s.slice_type === 'links_area');
    if (linksAreaIdx > -1) {
      slices.splice(
        linksAreaIdx,
        0,
        new FooterLinksAreaSlice(
          { slice_type: 'links_area', slice_label: null, primary: null, items: null, id: null },
          footerLinkSections
        )
      );
    }

    return new Footer(slices);
  }
}
