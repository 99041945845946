import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { BrandService } from 'brand';
import { CurvedBoxComponent } from 'curved-box';
import { SvgComponent } from 'icon';
import { LinkComponent } from 'link';
import { FooterCTAAreaSlice } from '../footer.class';

@Component({
  selector: 'lib-footer-cta-area',
  templateUrl: './footer-cta-area.component.html',
  standalone: true,
  imports: [SvgComponent, LinkComponent, CurvedBoxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterCtaAreaComponent {
  brand = inject<BrandService>(BrandService);
  @Input() slice: FooterCTAAreaSlice;
}
