import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { map, switchMap } from 'rxjs';
import { ActionCardComponent } from 'action-card';
import { BreadcrumbsComponent } from 'breadcrumbs';
import { SvgComponent } from 'icon';
import { LanguageService } from 'language';
import { CmsService } from 'prismic';
import { CategoryService } from '../category.service';
import { SupportArticle, SupportCategory } from '../support-types';
import { sortByDisplayOrder } from '../support-utils';
import { transformSupportDocLink } from '../transform-support-doc-link';
import { UrlifyTitlePipe } from '../urlify-title.pipe';

@Component({
  selector: 'app-category-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    UrlifyTitlePipe,
    ActionCardComponent,
    NgxSkeletonLoaderModule,
    SvgComponent,
    BreadcrumbsComponent,
  ],
  templateUrl: './category-page.component.html',
  styleUrl: './category-page.component.scss',
})
export class CategoryPageComponent {
  protected readonly transformCardLink = transformSupportDocLink;
  private route = inject(ActivatedRoute);
  private cms = inject(CmsService);
  private categoryService = inject(CategoryService);
  public languageService = inject(LanguageService);
  public category$ = this.route.data.pipe(map(data => data.category as PrismicDocument<SupportCategory>));
  public subCategories$ = this.category$.pipe(
    switchMap(category => this.cms.getByContentRelationship('category', 'parent', category.id)),
    map((categories: PrismicDocument<SupportCategory>[]) => {
      if (categories.length) {
        return sortByDisplayOrder(categories);
      }
      return [];
    })
  );
  public articles$ = this.category$.pipe(
    switchMap(category => this.cms.getByContentRelationship('article', 'category', category.id)),
    map((articles: PrismicDocument<SupportArticle>[]) => {
      if (articles.length) {
        return sortByDisplayOrder(articles);
      }
      return [];
    })
  );
  public categoryChain$ = this.category$.pipe(
    switchMap(category => this.categoryService.getCategoryChain(category.data.parent?.id))
  );
}
