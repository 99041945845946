<div class="mt-auto">
  <div class="flex items-end justify-center mt-2 mb-3">
    @if (loadingPrice()) {
      <mat-spinner [diameter]="50"></mat-spinner>
    } @else {
      <p
        class="text-xs transform -rotate-90 inline-block font-extrabold text-primary my-1 pl-2"
        [ngClass]="{ 'text-featured': promotion() && hasDiscountedCost() }"
      >
        CHF
      </p>
      @if (promotion() && hasDiscountedCost()) {
        <h1 class="md:xl-h:text-7xl text-5xl text-featured leading-none">
          {{ discountedPrice() | currencyFormatter }}
        </h1>
      } @else {
        <h1 class="text-7xl text-primary leading-none">
          {{ product()?.monthly_cost | currencyFormatter }}
        </h1>
      }
    }
  </div>

  <div class="flex justify-center">
    @if (promotion() && hasDiscountedCost()) {
      <div class="relative mr-2">
        <div class="absolute top-1/4 strikethrough"></div>
        <p class="font-bold text-primary md:xl-h:mb-4 mb-2 md:xl-h:text-base text-sm">
          {{ ['statt'] | translate }}
          {{ product()?.monthly_cost | currencyFormatter }}
        </p>
      </div>
    }
    <p class="font-bold text-primary md:xl-h:mb-4 mb-2 md:xl-h:text-base text-sm">
      {{ ['tariffPerMonth'] | translate }}
    </p>
  </div>
  @if (promotion() && hasDiscountedCost()) {
    <p class="font-semibold text-xs md:xl-h:mb-4 mb-2 text-[var(--color-gray-1)]">
      {{ ['discountForever'] | translate }}
    </p>
  }
</div>
