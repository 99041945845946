import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FooterBottomLinksAreaComponent } from './footer-bottom-links-area/footer-bottom-links-area.component';
import { FooterCtaAreaComponent } from './footer-cta-area/footer-cta-area.component';
import { FooterLinksAreaComponent } from './footer-links-area/footer-links-area.component';
import { Footer } from './footer.class';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [FooterBottomLinksAreaComponent, FooterLinksAreaComponent, FooterCtaAreaComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private cdr = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);
  footer: Footer;

  constructor() {
    this.route.data.subscribe(data => {
      this.footer = data.footer;
      this.cdr.markForCheck();
    });
  }
}
