@if (results()?.length) {
  <div class="relative">
    <div
      class="absolute top-0 left-0 w-full bg-white text-black flex flex-col p-4 rounded-lg max-h-80 overflow-y-scroll shadow-md"
    >
      @for (result of results(); track result.id) {
        <a
          [routerLink]="result.id"
          (click)="onSearchResultSelect.emit()"
          class="hover:bg-gray-200 rounded-lg p-2 text-left"
        >
          <div [innerHTML]="result.formattedQuestion"></div>
        </a>
      }
    </div>
  </div>
}
