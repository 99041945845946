import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LineCheckEligibleAccess, LineCheckProductClass, LineCheckResponse } from 'interfaces';
import { PCProduct } from 'product';

@Injectable({ providedIn: 'root' })
export class LineCheckProductsService {
  public lineCheckResultsShown$ = new BehaviorSubject(false);
  public hasEligibleProduct$ = new BehaviorSubject(false);

  public extractEligibleAccessByAddress(
    lineCheckData: LineCheckResponse,
    products: PCProduct[]
  ): LineCheckEligibleAccess {
    const isEligibleForProduct = (productClass: LineCheckProductClass) =>
      products?.some(
        ({ product_code: code, productSpecClass }) =>
          productClass === productSpecClass && lineCheckData.availableProductCodes.includes(code)
      );

    return {
      fiber: isEligibleForProduct('FIBER'),
      hfc: isEligibleForProduct('HFC'),
      mbb: isEligibleForProduct('MBB'),
    };
  }

  public hasEligibleProduct(productType: string, eligibleAccess: LineCheckEligibleAccess) {
    return eligibleAccess[productType?.toLowerCase() as keyof LineCheckEligibleAccess] ?? false;
  }

  public hasEligibleSpecialProduct(eligibleAccess: LineCheckEligibleAccess) {
    return Object.values(eligibleAccess).some(item => item === true);
  }
}
