<div class="newsletter">
  <div class="separator top-0 absolute"></div>
  <h4 class="text-label-email">{{ ['placeholderNewsLetter'] | translate }}</h4>
  <form [formGroup]="newsletterForm" (ngSubmit)="submit()" class="relative">
    <div
      [ngClass]="{
        'line-check-box__input--invalid': formSubmitAttempt && newsletterForm.invalid,
        newsletter__input: brand.isYallo(),
      }"
      class="line-check-box__input border-color-gray-3 w-full lg:col-span-3 pl-4 mb-3 sm:mb-0 lg:mb-0"
    >
      <lib-svg class="mr-2 text-gray-2 normal-plus" svg="analog-mail"></lib-svg>
      <input
        class="line-check-box__input-text text-dark flex-grow pl-2.5"
        type="email"
        id="email"
        name="email"
        [placeholder]="['emailAddressPlaceholder'] | translate"
        formControlName="email"
      />
      <button class="button" type="submit" [ngClass]="{ 'line-check-box__button--loading': loading }">
        <span [ngClass]="{ invisible: loading }">{{ ['newsletterSignUpButton'] | translate }}</span>
      </button>
    </div>

    @if (formSubmitAttempt) {
      <div class="results absolute top-1 left-4 text-xs font-bold leading-3">
        @if (newsletterForm.touched) {
          <div class="errors">
            @if (newsletterForm.hasError('required', 'email')) {
              <span>{{ ['fieldRequired'] | translate }}</span>
            }
            @if (newsletterForm.hasError('email', 'email') && newsletterForm.controls['email'] !== null) {
              <span [translate]="'validEmailRequired'"></span>
            }
          </div>
        }
      </div>
    }
  </form>
</div>
