<ng-template #productBodyTemplate>
  <h4
    class="text-center my-2 px-2"
    [ngClass]="{
      'lg:text-5xl text-3xl': lightMode(),
      'lg:text-3xl text-1xl': !lightMode(),
      'font-black': !lightMode(),
      'bg-gradient-to-r from-[--color-primary-lightened] to-[--color-blue-darkened] to-80% text-transparent bg-clip-text':
        isYallo,
      'text-[--color-blue-light]': !isYallo,
    }"
  >
    {{ product().name }}
  </h4>
  <div class="px-5 flex flex-col justify-between flex-1">
    @if (!lightMode()) {
      @if (!product().prismicTable) {
        <ngx-dynamic-hooks
          class="text-center flex-auto"
          [ngClass]="{ 'text-light-gray': !isYallo }"
          [content]="product().short_description"
          [options]="{ sanitize: false }"
        >
        </ngx-dynamic-hooks>
      }
      <div class="mb-6">
        @switch (product().prismicTable?.type) {
          @case ('table_mobile') {
            <lib-table-mobile [product]="product()"></lib-table-mobile>
          }
          @case ('table_home_internet') {
            <lib-table-home-internet [product]="product()"></lib-table-home-internet>
          }
          @case ('table_data_products') {
            <lib-table-data-products [product]="product()"></lib-table-data-products>
          }
          @case ('table_tv') {
            <lib-table-tv [product]="product()"></lib-table-tv>
          }
        }
      </div>
    }

    <lib-product-box-price
      [productItem]="productItem()"
      [hasDiscountedCost]="hasDiscountedCost()"
      [discountedPrice]="discountedPrice()"
    ></lib-product-box-price>
  </div>
</ng-template>

@if (product()) {
  <div
    class="relative product text-center h-full flex flex-col border border-solid border-[--color-gray-3]"
    [ngClass]="{ 'sales-box': !lightMode(), 'sales-box-no-shadow': lightMode() }"
    data-testid="product-box"
  >
    <div class="h-8">
      @if (promotion() && hasDiscountedCost() && !lightMode()) {
        <lib-promotion-banner
          [countdownDate]="productItem().countdownDate"
          [text]="promotionBannerText()"
          [dynamicSize]="false"
        >
        </lib-promotion-banner>
      }
    </div>
    @if (!lightMode()) {
      <lib-link
        [attr.data-testid]="'product-' + product().product_code | lowercase"
        [link]="ctaLink()"
        [queryParams]="queryParams()"
        [queryParamsHandling]="'merge'"
        (onClick)="onProductBoxClick()"
        class="flex-auto flex flex-col [&>a]:flex-auto [&>a]:flex [&>a]:flex-col [&>a>span]:flex-auto"
      >
        <div class="flex flex-col h-full">
          <ng-container *ngTemplateOutlet="productBodyTemplate"></ng-container>
        </div>
      </lib-link>
    } @else {
      <ng-container *ngTemplateOutlet="productBodyTemplate"></ng-container>
    }
    <div class="flex justify-center mb-4 px-5">
      <lib-link
        class="w-48"
        [link]="ctaLink()"
        [queryParams]="queryParams()"
        [queryParamsHandling]="'merge'"
        (onClick)="onProductBoxClick()"
      >
        <button class="button text-center w-full min-w-44 text-sm" [ngClass]="ctaStyling().classes">
          {{ ctaStyling().label }}
        </button>
      </lib-link>
    </div>
    @if (showLearnMore() && !lightMode()) {
      <lib-link
        [attr.data-testid]="'product-detail-' + product().product_code | lowercase"
        [textCssClass]="'flex justify-center items-center text-gray-2 text-sm font-medium mb-5'"
        [link]="productDetailsLink()"
      >
        <lib-svg svg="info-outline" class="normal"></lib-svg>
        {{ productDetailsLinkLabel() }}
      </lib-link>
    }
  </div>
}
