import { Type } from '@angular/core';

export class Toast {
  constructor(
    public message: string,
    public duration: number,
    public isGood: boolean,
    public component: { comp: Type<unknown>; inputs: { [key: string]: unknown } },
    public hideIcon: boolean
  ) {}
}
