import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BrandService } from 'brand';
import { CurvedBoxComponent } from 'curved-box';
import { FooterComponent } from 'footer';
import { MenuGlobalComponent } from 'menu';
import { NavigationComponent } from 'navigation';
import { TranslatePipe } from 'translate';
import { appendScript } from 'utils';
import { environment } from '../../environments/environment';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-base',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    NavigationComponent,
    MenuGlobalComponent,
    FooterComponent,
    CurvedBoxComponent,
    SearchComponent,
    TranslatePipe,
  ],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
})
export class BaseComponent implements OnInit {
  private document = inject(DOCUMENT);
  private brand = inject(BrandService);

  ngOnInit(): void {
    if (!environment.productionMode) {
      appendScript(this.document, `https://static.cdn.prismic.io/prismic.js?new=true&repo=${this.brand.brand}-support`);
    }
  }
}
