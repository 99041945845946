import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'translate',
  standalone: true,
  pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  private path: string[];
  private value: string;
  private readonly subscription: Subscription;
  private markForTransform = true;

  constructor() {
    this.subscription = this.translateService.onTranslationComplete.subscribe(() => {
      this.markForTransform = true;
      this.cdr.markForCheck();
    });
  }

  public transform(path: string[]): string {
    if (!this.markForTransform && this.path === path) {
      return this.value;
    }
    this.path = path;
    this.value = this.translateService.getTranslation(path);
    this.markForTransform = false;
    return this.value;
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
