import { Injectable, Type } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { Toast } from './toast.class';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  observer: Subscriber<unknown>;
  public toasts = new Observable(observer => {
    this.observer = observer;
  });

  public add(
    message: string,
    isGood = true,
    duration = 3000,
    component: { comp: Type<unknown>; inputs: { [key: string]: unknown } } = null,
    hideIcon = false
  ) {
    if (this.observer) {
      this.observer.next(new Toast(message, duration, isGood, component, hideIcon));
    }
  }
}
