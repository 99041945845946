import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgComponent } from 'icon';
import { PCProduct } from 'product';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'lib-table-tv',
  standalone: true,
  imports: [SvgComponent, TranslatePipe],
  templateUrl: './table-tv.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTvComponent {
  @Input() product: PCProduct;
}
