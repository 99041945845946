<div class="my-6">
  @if (isSmallScreenSize()) {
    <span class="mx-2 text-[--color-text-secondary]"> < </span>
    <a routerLink=".." class="cursor-pointer">
      <span class="text-[--color-link-default] hover:text-[--color-link-hover] active:text-[--color-link-active]">
        {{ ['login', 'back'] | translate }}
      </span>
    </a>
  } @else {
    <a [routerLink]="['/', languageService.current]">
      <span class="text-[--color-link-default] hover:text-[--color-link-hover] active:text-[--color-link-active]">
        {{ ['support', 'first_breadcrumb_text'] | translate }}
      </span>
    </a>
    @for (category of categoryChain(); track category.id) {
      <span class="mx-2 text-[--color-text-secondary]"> > </span>
      <a [routerLink]="['/', languageService.current, category.id]">
        <span class="text-[--color-link-default] hover:text-[--color-link-hover] active:text-[--color-link-active]">
          {{ category.data.title }}
        </span>
      </a>
    }
    <span class="mx-2 text-[--color-text-secondary]"> > </span>
    <span class="text-[--color-text-secondary]">{{ mainTitle() }}</span>
  }
</div>
