import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SvgComponent } from 'icon';

@Component({
  selector: 'lib-curved-box',
  templateUrl: './curved-box.component.html',
  styleUrls: ['./curved-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SvgComponent, NgClass, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurvedBoxComponent {
  @Input() backgroundImage!: string;
  @Input() curve: 'top' | 'bottom' | 'both' | 'top-inside' | 'none' = 'both';
  @Input() height: 50 | 75 | 100 = 100;
  @Input() topCurveClasses!: string | string[];
  @Input() bottomCurveClasses!: string | string[];
  @Input() contentStyle!: { [klass: string]: unknown };
  @Input() dontHideOverflow: boolean;

  get _bottomCurveClasses() {
    let classes: Array<string> = [];
    if (this.bottomCurveClasses) {
      if (Array.isArray(this.bottomCurveClasses)) {
        classes = this.bottomCurveClasses;
      } else {
        classes = [this.bottomCurveClasses];
      }
    }

    if (this.height === 50) {
      // If the height is only half we need to position the SVG
      classes = classes.concat(['w-full', 'absolute', 'top-1/2']);
    }
    if (this.height === 75) {
      // If the height is only 3/4 we need to position the SVG
      classes = classes.concat(['w-full', 'absolute', 'top-3/4']);
    }

    classes.push('mt-1');

    return classes;
  }

  get _topCurveClasses() {
    let classes: Array<string> = [];
    if (this.topCurveClasses) {
      if (Array.isArray(this.topCurveClasses)) {
        classes = this.topCurveClasses;
      } else {
        classes = [this.topCurveClasses];
      }
    }

    return classes;
  }
}
