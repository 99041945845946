<table class="mt-2 border-collapse border-hidden w-full [&_td]:p-2 [&_td]:border [&_td]:border-zinc-200">
  <tbody>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="tv2"></lib-svg>
        {{ ['tvChannels'] | translate }}
      </td>
      <td [innerHTML]="$any(product.prismicTable).tvChannels"></td>
    </tr>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="tv_record"></lib-svg>
        {{ ['recording'] | translate }}
      </td>
      <td [innerHTML]="$any(product.prismicTable).recording"></td>
    </tr>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="gear"></lib-svg>
        {{ ['tvBox'] | translate }}
      </td>
      <td class="font-bold whitespace-nowrap">
        @if ($any(product.prismicTable).tvBox) {
          <lib-svg class="flex justify-center fill-[--color-petrol]" svg="fontawesome/check"></lib-svg>
        } @else {
          -
        }
      </td>
    </tr>
  </tbody>
</table>
