import { inject, Injectable } from '@angular/core';
import { PrismicDocument } from '@prismicio/client';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { LanguageService } from 'language';
import { CmsService } from 'prismic';
import { SupportCategory } from './support-types';
import { urlifyTitle } from './urlify-title';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  cmsService = inject(CmsService);
  lang = inject(LanguageService);
  cms = inject(CmsService);

  public getCategories() {
    return from(this.cms.getByType('category')).pipe(
      map(categories =>
        (categories as PrismicDocument<SupportCategory>[]).filter(category => !category.data.parent?.id)
      )
    );
  }

  public getCategoryPath(category: PrismicDocument<SupportCategory>): Observable<string> {
    if (category.lang !== this.cmsService.languages[this.lang.current]) {
      const correctLangId = category.alternate_languages.find(
        lang => lang.lang === this.cmsService.languages[this.lang.current]
      )?.id;
      return of(`/${this.lang.current}/${correctLangId}`);
    }
    return of(`/${this.lang.current}/${category.id}-${urlifyTitle(category.data.title)}`);
  }

  public getCategoryChain(categoryId: string): Observable<PrismicDocument<SupportCategory>[]> {
    if (!categoryId) return of([]);
    return from(this.cmsService.getById(categoryId)).pipe(
      switchMap(category => {
        return this.getCategoryChain(category.data.parent?.id).pipe(
          map(parentChain => {
            return [...parentChain, category as PrismicDocument<SupportCategory>];
          })
        );
      })
    );
  }
}
