@if (footer?.slices; as slices) {
  @for (slice of slices; track slice) {
    @if (slice.type === 'cta_area') {
      <lib-footer-cta-area [slice]="$any(slice)"></lib-footer-cta-area>
    }
    @if (slice.type === 'links_area') {
      <lib-footer-links-area class="relative" [slice]="$any(slice)"> </lib-footer-links-area>
    }
    @if (slice.type === 'bottom_links_area') {
      <lib-footer-bottom-links-area class="relative" [slice]="$any(slice)"> </lib-footer-bottom-links-area>
    }
  }
}
