import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { catchError, from, map, of } from 'rxjs';
import { CmsService } from 'prismic';
import { extractPrismicIdFromUrl } from '../extract-prismic-id-from-url';

export const isArticleGuard: CanMatchFn = (route, url) => {
  const cmsService = inject(CmsService);
  const path = url[url.length - 1].path;
  const id = extractPrismicIdFromUrl(path);
  if (!id) return of(false);

  return from(cmsService.getById(id, '*')).pipe(
    map(doc => doc && doc.type === 'article'),
    catchError(() => of(false))
  );
};
