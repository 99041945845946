<mat-form-field
  [appearance]="appearance()"
  [class]="class"
  [color]="theme"
  [subscriptSizing]="subscriptSizing() || (desktopView ? 'fixed' : 'dynamic')"
>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  @if (icon) {
    <lib-svg
      [svg]="icon"
      matIconPrefix
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
    ></lib-svg>
  }
  <div class="flex flex-row items-center">
    <input
      #inputEl
      [libAutofocus]="autoFocus"
      matInput
      [maxlength]="maxLength"
      class="text-dark"
      [ngClass]="{ '!hidden': mask }"
      [formControl]="control"
      [errorStateMatcher]="errorMatcher()"
      [type]="type"
      [name]="name"
      [placeholder]="placeholder"
      [size]="size"
      [readonly]="readonly"
      (input)="_onInput($event)"
      (blur)="_onBlur()"
      [autocomplete]="autocomplete"
      (focus)="_onFocus()"
      data-testid="anonymous-new-input"
    />
    @if (mask) {
      <input
        #maskedInput
        matInput
        [(ngModel)]="inputEl.value"
        (input)="dispatch(inputEl, 'input', $event)"
        (blur)="dispatch(inputEl, 'blur')"
        (focus)="dispatch(inputEl, 'focus')"
        [mask]="mask"
        [showMaskTyped]="showMaskedInput ? inputOnFocus || control.value : false"
        [inputTransformFn]="maskInputTransformFn"
        [patterns]="maskCustomPatterns"
        [placeholder]="placeholder"
      />
    }
    @if (labelSuffix) {
      <span aria-atomic="true" matTextSuffix aria-live="polite">{{ labelSuffix }}</span>
    }

    @if (showClearInput) {
      <lib-svg
        svg="clear-input"
        matIconSuffix
        class="pr-3 cursor-pointer w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] block box-content"
        (click)="onClearInput()"
      ></lib-svg>
    }

    @if (showActionButton) {
      <div class="rounded-full hover:bg-[--color-gray-opacity] w-6 h-6 cursor-pointer flex items-center justify-center">
        <lib-svg
          svg="fontawesome/arrow-right"
          matSuffix
          class="[&_svg]:fill-[--color-primary] block box-content"
          (click)="onActionButtonClick()"
        ></lib-svg>
      </div>
    }

    @if (iconSuffix) {
      <lib-svg
        [svg]="iconSuffix"
        matIconSuffix
        class="w-6 h-6 [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] [&_svg]:w-auto [&_svg]:h-auto p-3 block box-content"
      ></lib-svg>
    }

    @if (loading) {
      <img matIconSuffix src="/resources/small-spinner.gif" class="h-5 mx-2 block box-content z-50" />
    }

    @if (!loading && isBackendValid && ngControl.valid && ngControl.enabled && ngControl.value) {
      <lib-svg
        matIconSuffix
        [svg]="checkmarkIcon"
        class="w-6 [&_svg]:w-full [&_svg]:h-auto block box-content"
      ></lib-svg>
    }
    @if (showHidePassword) {
      <lib-svg
        matIconSuffix
        [svg]="showPassword ? 'fontawesome/eye-slash' : 'fontawesome/eye'"
        (click)="togglePasswordVisibility()"
        class="pr-1 [&_svg]:fill-[--color-gray-2]"
      ></lib-svg>
    }
  </div>
  @if (
    showErrors() &&
    controlErrorMessage &&
    ((parentControl?.errors && parentControl?.errors['required'] && !parentControl?.value) ||
      (parentControl?.invalid && parentControl?.value))
  ) {
    <mat-error>
      {{ ['form_errors', controlName, controlErrorMessage] | translate }}
    </mat-error>
  }
</mat-form-field>
