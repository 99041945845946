import { inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Footer } from './footer.class';
import { FooterService } from './footer.service';

@Injectable({
  providedIn: 'root',
})
export class FooterResolver implements Resolve<Footer> {
  private footerService = inject(FooterService);

  async resolve(): Promise<Footer> {
    return this.footerService.getFooter();
  }
}
