import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { map } from 'rxjs';
import { LanguageService } from 'language';
import { TranslatePipe } from 'translate';

export type Category = {
  title?: string;
  parent?: { id?: string };
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslatePipe],
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent {
  private breakpointObserver = inject(BreakpointObserver);
  public languageService = inject(LanguageService);
  categoryChain = input<PrismicDocument<Category, string, string>[]>();
  mainTitle = input<string>();
  public isSmallScreenSize = toSignal(
    this.breakpointObserver.observe('(min-width: 768px)').pipe(map(({ matches }) => !matches))
  );
}
