@let category = category$ | async;
@let categoryChain = categoryChain$ | async;
@let articles = articles$ | async;
@let subCategories = subCategories$ | async;

<lib-breadcrumbs [categoryChain]="categoryChain" [mainTitle]="category.data.title" />

<h1 class="text-4xl text-center my-6">{{ category.data.title }}</h1>
@defer (when subCategories?.length) {
  <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 justify-center gap-10">
    @for (subCategory of subCategories; track subCategory.id) {
      <lib-action-card
        [link]="transformCardLink(subCategory, languageService.current)"
        [title]="subCategory.data.title"
        class="grid min-h-32 text-center"
      />
    }
  </div>
} @loading (after 100ms; minimum 1000ms) {
  <ngx-skeleton-loader
    class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 justify-center gap-10 [&_.skeleton-loader]:!h-28 [&_.skeleton-loader]:!md:h-56"
    count="3"
  />
}

@defer (when articles?.length) {
  <ul class="flex flex-col gap-2">
    @for (article of articles; track article.id) {
      <li>
        <a
          [routerLink]="['/', languageService.current, article.id + '-' + (article.data.title | urlifyTitle)]"
          class="group text-[--color-link-default] hover:text-[--color-link-hover] active:text-[--color-link-active] visited:text-[--color-link-active] hover:bg-[--blue-50] active:bg-transparent px-2 py-1.5 rounded-lg transition-colors inline-flex gap-2 items-center"
        >
          <lib-svg
            class="fill-[--color-link-default] group-hover:[&_svg]:fill-[--color-link-hover] group-active:[&_svg]:fill-[--color-link-active] group-visited:[&_svg]:fill-[--color-link-active]"
            svg="fontawesome/file-lines-regular"
          />
          {{ article.data.title }}
        </a>
      </li>
    }
  </ul>
} @loading (after 100ms; minimum 1000ms) {
  <ngx-skeleton-loader class="flex flex-col gap-2 [&_.skeleton-loader]:!h-5" count="5" />
}
