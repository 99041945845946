<div class="expansion-panel" [class.open]="isOpen" [class.light]="parentBackground === 'dark'">
  <div
    class="expansion-panel-header relative h-16 border-inherit after:content-['_'] sm:px-7 px-4 text-sm sm:text-base mt-2"
    [style.background]="parentBackground === 'dark' ? 'dark' : 'var(--color-primary-opacity)'"
    [ngClass]="{
      light_indicator: parentBackground !== 'dark',
      'rounded-ss-lg rounded-se-lg': isOpen,
      'rounded-lg': !isOpen,
      'after:content-none': isOpen && !isToggleEnabled,
      'cursor-auto': !isToggleEnabled,
    }"
    (click)="isToggleEnabled ? toggle() : null"
    (keydown)="onKeyDown($event)"
    role="button"
    (keydown.enter)="toggle()"
    [id]="'expansion-panel-header-' + index"
    tabindex="0"
    [attr.aria-controls]="'accordion-child-' + index"
    [attr.aria-expanded]="isOpen"
    aria-disabled="false"
  >
    <ng-content select="[header]"></ng-content>
  </div>
  @if (isOpen) {
    <div
      class="py-4 sm:px-7 px-4 accordion-body shadow-[0_2px_8px_rgba(0,0,0,0.1)] rounded-ee-lg rounded-es-lg border-inherit"
      [ngClass]="{ 'shadow-none	': parentBackground === 'dark' }"
      [style.background]="parentBackground === 'dark' ? 'dark' : 'white'"
      [@accordionBody]
      [attr.aria-labelledby]="'expansion-panel-header-' + index"
      [id]="'accordion-child-' + index"
    >
      <ng-content></ng-content>
    </div>
  }
</div>
