import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccordionComponent, AccordionItemComponent } from 'accordion';
import { LinkComponent } from 'link';
import { NewsletterComponent } from 'newsletter';
import { FooterLinksAreaSlice } from '../footer.class';

@Component({
  selector: 'lib-footer-links-area',
  templateUrl: './footer-links-area.component.html',
  standalone: true,
  imports: [LinkComponent, AccordionItemComponent, AccordionComponent, NewsletterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinksAreaComponent {
  @Input() slice: FooterLinksAreaSlice;
}
