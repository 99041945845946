<div
  class="curved-box bg-linear"
  [ngClass]="{
    'bg-linear-50': height === 50,
    'bg-linear-75': height === 75,
    'pb-8': curve === 'bottom' || curve === 'both',
    'pt-8': curve === 'top' || curve === 'both',
    'overflow-hidden': !dontHideOverflow,
  }"
  [style.backgroundImage]="backgroundImage ? 'url(' + backgroundImage + ')' : undefined"
>
  @if (curve === 'both' || curve === 'bottom') {
    <lib-svg [ngClass]="_bottomCurveClasses" svg="curve-outside-bottom"> </lib-svg>
  }

  <div class="content transition-all duration-300 max-h-full" [ngStyle]="contentStyle">
    <ng-content></ng-content>
  </div>
  @if (curve === 'both' || curve === 'top') {
    <lib-svg [ngClass]="_topCurveClasses" svg="curve-outside-top"></lib-svg>
  }
  @if (curve === 'top-inside') {
    <lib-svg svg="curve-inside"> </lib-svg>
  }
</div>
