<lib-newsletter />
<div class="bg-color-footer">
  <div class="container flex flex-col justify-center">
    <!-- MOBILE FOOTER LINKS START -->

    @if (slice.linkSections && slice.linkSections.length > 0) {
      <lib-accordion class="lg:hidden mx-5">
        @for (section of slice.linkSections; track section; let i = $index) {
          <lib-accordion-item [parentBackground]="'dark'" [index]="i">
            <div header>
              {{ section.title }}
            </div>
            <ul>
              @for (l of section.linkItems; track l) {
                <li class="mt-2 text-color-footer footer__link-text--hover">
                  <lib-link [link]="l.url">{{ l.title }}</lib-link>
                </li>
              }
            </ul>
          </lib-accordion-item>
          @if (i !== slice.linkSections.length - 1) {
            <hr class="my-4 bg-color-gray-2 opacity-20" />
          }
        }
      </lib-accordion>
    }

    <!-- MOBILE FOOTER LINKS END -->
    <!-- DESKTOP FOOTER LINKS START -->
    @if (slice.linkSections && slice.linkSections.length > 0) {
      <div class="hidden lg:flex justify-between mx-32 py-16">
        @for (section of slice.linkSections; track section; let i = $index) {
          <div>
            <p class="text-lg text-white font-bold text-center mb-4">{{ section.title }}</p>
            @for (l of section.linkItems; track l) {
              <p class="text-base text-color-footer footer__link-text--hover text-center mb-3">
                <lib-link [link]="l.url">{{ l.title }}</lib-link>
              </p>
            }
          </div>
        }
      </div>
    }
    <!-- DESKTOP FOOTER LINKS END -->
  </div>
</div>
