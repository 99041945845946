import { DOCUMENT, isPlatformBrowser, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FilledContentRelationshipField, FilledLinkToWebField, LinkType } from '@prismicio/client';
import { fromEvent, Subscription } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { AnalyticsService } from 'analytics';
import { BrandService } from 'brand';
import { DropdownMenuComponent } from 'dropdown-menu';
import { SvgComponent } from 'icon';
import { Image, lang, MenuItem, SimpleMenuItem } from 'interfaces';
import { LanguageService } from 'language';
import { LinkComponent } from 'link';
import { LanguageSelectorComponent, MenuComponent, MenuItemComponent } from 'menu';
import { prismicToImage } from 'prismic';
import { ProductBoxComponent } from 'product-box';
import { TranslatePipe, TranslateService } from 'translate';
import { BrowserService } from 'utils';
import { MenuItemWithDropdown, SecondLevelMenuItem } from '../menu.interface';

@Component({
  selector: 'lib-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    LinkComponent,
    ProductBoxComponent,
    LanguageSelectorComponent,
    MenuItemComponent,
    MenuComponent,
    TranslatePipe,
    SvgComponent,
    DropdownMenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit, OnDestroy {
  private document = inject<Document>(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private route = inject(ActivatedRoute);
  private browserService = inject(BrowserService);
  lang = inject(LanguageService);
  brand = inject(BrandService);
  private translateService = inject(TranslateService);
  analytics = inject(AnalyticsService);
  private cdr = inject(ChangeDetectorRef);
  readonly LinkType = LinkType;
  menuItems: MenuItem[];
  host: string;
  public open = false;
  selectedDropdown: number = undefined;
  phone: { phoneNumber: string; phoneNumberLink: FilledLinkToWebField };
  scrolled = false;
  private scrollSub: Subscription;
  ariaLabel: string;
  logo: Image;
  themeColor: string;
  @ViewChildren('navbar') navBar: QueryList<ElementRef>;
  @ViewChildren('dropdownItem') dropDownItems: QueryList<ElementRef>;
  private currentPath: Array<string> = [];
  readonly tvSlug = 'login-tv';
  isLogin = false;

  constructor() {
    this.route.data.subscribe(data => {
      this.menuItems = data.mainNavigation?.nav;
      this.phone = data.mainNavigation?.phone;
      if (Object.keys(data.mainNavigation?.logo).length !== 0) {
        this.logo = prismicToImage(data.mainNavigation?.logo);
      }
      this.themeColor = data.mainNavigation?.themeColor;
    });
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      this.closeEverything();
      this.cdr.markForCheck();
    });
    // Listen for clicks off the menu and react accordingly
    this.renderer.listen('window', 'click', (e: Event) => {
      /*
       * Only run if the click is outside the menu itself
       */
      //Because of bubbling we need to find if one of the paths match the navBar
      for (const elRef of this.navBar) {
        if (e.composedPath().indexOf(elRef.nativeElement) >= 0) {
          return;
        }
      }
      for (const elRef of this.dropDownItems) {
        //Because of bubbling we need to find if one of the paths match the elRef
        if (e.composedPath().indexOf(elRef.nativeElement) >= 0) {
          return;
        }
      }
      this.selectedDropdown = undefined;
    });
  }

  async ngOnInit(): Promise<void> {
    const isTvUrl = this.router.url.includes(this.tvSlug);
    if (this.router.url.includes('login')) this.isLogin = true;

    if (isTvUrl) this.renderer.addClass(this.document.body, 'tv-theme');
    else this.renderer.removeClass(this.document.body, 'tv-theme');

    this.host = this.browserService.getHost();
    this.currentPath = this.router.url.split('/');
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
      this.currentPath = (e as NavigationEnd).urlAfterRedirects.split('/');
    });

    if (isPlatformBrowser(this.platformId)) {
      this.scrollSub = fromEvent(this.document, 'scroll')
        .pipe(
          throttleTime(10),
          tap(e => {
            this.scrolled = (e.target as Document).scrollingElement.scrollTop > 50;
          })
        )
        .subscribe(() => {
          this.cdr.markForCheck();
        });
    }
    this.ariaLabel = `${this.translateService.getTranslation(['telephoneNumber'])}. ${this.phone.phoneNumber}`;

    if (this.themeColor) this.turnOnCustomTheme();

    this.cdr.markForCheck();
  }

  turnOnCustomTheme() {
    this.document.documentElement.style.setProperty('--color-theme', this.themeColor);
    const customTheme = this.document.body.classList.contains('custom-theme');
    if (!customTheme) {
      this.document.body.classList.add('custom-theme');
    }
  }

  get languageAgnosticPath(): string[] {
    let path = this.currentPath;
    path = path.filter((p: string) => {
      return p.trim() !== '' && !this.lang.langs.includes(p as lang);
    });
    return path;
  }

  ngOnDestroy(): void {
    this.closeEverything();
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSub?.unsubscribe();
    }
  }

  isSimpleMenuItem(item: MenuItem) {
    return item instanceof SimpleMenuItem ? item : undefined;
  }

  isSimpleItemActive(item: MenuItem): boolean {
    const currentRoute = this.router.url;
    const slugItem = (item.link as FilledContentRelationshipField).slug;
    const slug = currentRoute.slice(4);
    const pattern = slug.includes('mobile-products');
    const tvArray = ['tv-ads', 'tv-base', 'tv-free-signup', 'tv-free', 'tv-upgrade', 'tv'];

    if (currentRoute.slice(4) === slugItem || tvArray.indexOf(slug) > -1 || (this.brand.isLebara() && pattern)) {
      return !!slugItem;
    }
  }

  isMenuItemWithDropdown(item: MenuItem) {
    return item instanceof MenuItemWithDropdown ? item : undefined;
  }

  isDropdownActive(item: MenuItemWithDropdown): boolean {
    const currentRoute = this.router.url;
    const slug = currentRoute.slice(4);
    const internetArray = [
      'best-speed',
      'best-speed-tv',
      'cable-m-plus-tv',
      'cable',
      'cable-m',
      'cable-s',
      'cable-plus-tv',
      'cable-s-plus-tv',
      'home',
      'home-4g',
      'home-5g',
      'home-internet',
      'internet-go',
      'home-5g-plus-tv',
      'cancel-home-internet',
      'fiber',
      'fiber-plus-tv',
    ];

    if (internetArray.indexOf(slug) > -1) {
      return item.title === 'Internet';
    } else
      return (
        item.secondLevelEntries.findIndex(entry => {
          if ('slug' in entry.link) return slug.includes(entry.link.slug);
        }) > -1
      );
  }

  addAnalytics(analyticsData: {
    interaction_name: string;
    mobile_nav_submenu_name?: string;
    mobile_nav_link_name?: string;
  }) {
    const data = {
      event: 'gtm visitor interaction',
      ...analyticsData,
    };
    this.analytics.customEvent(data);
  }

  toggleNav() {
    this.open = !this.open;
    this.toggleOverflow();
    this.toggleNavAnalytics(this.open);
  }

  toggleOverflow() {
    if (this.open) {
      this.renderer.addClass(this.document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
    }
  }

  private toggleNavAnalytics(open: boolean) {
    const analyticsData = {
      interaction_name: `mobile nav ${open ? 'open' : 'closed'}`,
    };
    this.addAnalytics(analyticsData);
  }

  selectDropdown(menuItemIndex: number, item: MenuItem) {
    let analyticsData;
    if (this.selectedDropdown === menuItemIndex) {
      this.selectedDropdown = undefined;
      analyticsData = {
        interaction_name: 'mobile nav submenu collapse',
        mobile_nav_submenu_name: item.title.toLowerCase(),
      };
    } else {
      this.selectedDropdown = menuItemIndex;
      analyticsData = {
        interaction_name: 'mobile nav submenu expand',
        mobile_nav_submenu_name: item.title.toLowerCase(),
      };
    }
    this.addAnalytics(analyticsData);
  }

  onSubItemClick(item: MenuItem | SimpleMenuItem, secondLevelItem: SecondLevelMenuItem | null) {
    const analyticsData = {
      interaction_name: 'menu interaction',
      mobile_nav_link_name: [item.title.toLowerCase(), secondLevelItem?.title.toLowerCase()]
        .filter(Boolean)
        .join(' - '),
    };
    this.addAnalytics(analyticsData);
  }

  isDropdownSelected(menuItemIndex: number) {
    return this.selectedDropdown === menuItemIndex;
  }

  getPath(lang: lang): string {
    return `${lang}/${this.languageAgnosticPath}`;
  }

  closeEverything(): void {
    this.open = false;
    this.selectedDropdown = undefined;
    this.toggleOverflow();
  }

  skipNav(evt: Event) {
    evt.preventDefault();

    const content = this.document.querySelector('#content') as HTMLElement;

    if (content) {
      content.focus();
    }
  }

  get currentLang(): lang {
    return this.route.snapshot.params['lang'];
  }

  getSubItemLink(subItem: SecondLevelMenuItem) {
    switch (subItem.link.link_type) {
      case LinkType.Document:
        return {
          routerLink: ['/', this.lang.current, ...(subItem.link as FilledContentRelationshipField).slug.split('/')],
        };
      case LinkType.Web:
        return { rawUrl: (subItem.link as FilledLinkToWebField).url };
    }
  }

  generateTestIdName(menuTitle: string) {
    return menuTitle.toLowerCase().replace(/\s/g, '-');
  }
}
