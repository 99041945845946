<table class="mt-2 border-collapse border-hidden w-full [&_td]:p-2 [&_td]:border [&_td]:border-zinc-200">
  <tbody>
    <tr>
      <td></td>
      <td class="font-bold whitespace-nowrap">
        <div class="flex flex-col items-center relative">
          <lib-svg svg="swiss-flag-colored"></lib-svg>
          {{ ['switzerland'] | translate }}
        </div>
      </td>
    </tr>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="fontawesome/wifi"></lib-svg>
        {{ ['dataInternet'] | translate }}
      </td>
      <td [innerHTML]="$any(product.prismicTable).data"></td>
    </tr>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="fontawesome/gauge"></lib-svg>
        {{ ['speed'] | translate }}
      </td>
      <td [innerHTML]="$any(product.prismicTable).speed"></td>
    </tr>
  </tbody>
</table>
