import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { catchError, from, of, switchMap } from 'rxjs';
import { CmsService } from 'prismic';
import { RedirectService } from 'redirect';
import { routeSnapshotToString } from 'utils';
import { CategoryService } from '../category.service';
import { SupportCategory } from '../support-types';

export const zendeskCategoryRedirect: CanActivateFn = route => {
  const redirectService = inject(RedirectService);
  const cmsService = inject(CmsService);
  const categoryService = inject(CategoryService);
  const path = route.params.category;
  const id = path.split('-')[0];

  return from(cmsService.getByFieldValue('category', 'zendesk_id', id)).pipe(
    switchMap((category: PrismicDocument<SupportCategory>) => categoryService.getCategoryPath(category)),
    switchMap(newPath => {
      if (newPath === routeSnapshotToString(route)) return of(true);
      return redirectService.redirectTo(newPath);
    }),
    catchError(() => {
      return redirectService.redirectTo('/');
    })
  );
};
