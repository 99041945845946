<lib-curved-box [curve]="brand.isYallo() ? 'top' : 'none'">
  <div class="container">
    <div class="py-10 mx-5 flex flex-col lg:flex-row justify-between">
      @for (link of slice.linkItems; track link; let i = $index) {
        <div class="w-full lg:max-w-xs">
          <lib-link [link]="link.url">
            <div class="flex justify-between items-center footer-cta-item text-white">
              <div
                class="flex items-center justify-center p-3 lg:border-2 border-opacity-40 border-white rounded-full mr-3"
              >
                @if (link.icon) {
                  <lib-svg class="white medium" [svg]="link.icon"></lib-svg>
                }
              </div>
              <div class="grow flex flex-col">
                <div data-source="prismic" [innerHtml]="link.title">{{ link.title }}</div>
                <div data-source="prismic" class="hidden lg:block" [innerHtml]="link.description"></div>
              </div>
              <lib-svg class="lg:hidden white normal" svg="fontawesome/arrow-right"></lib-svg>
            </div>
          </lib-link>
          @if (i !== slice.linkItems.length - 1) {
            <hr class="my-2 bg-white opacity-40 lg:hidden" />
          }
        </div>
      }
    </div>
  </div>
</lib-curved-box>
