<table class="mt-2 border-collapse border-hidden w-full [&_td]:p-2 [&_td]:border [&_td]:border-zinc-200">
  <tbody>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="fontawesome/gauge"></lib-svg>
        {{ ['speed'] | translate }}
      </td>
      <td [innerHTML]="$any(product.prismicTable).speed"></td>
    </tr>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="gear"></lib-svg>
        {{ ['internetBox'] | translate }}
      </td>
      <td>
        @if ($any(product.prismicTable).internetBox) {
          <lib-svg class="flex justify-center fill-[--color-petrol]" svg="fontawesome/check"></lib-svg>
        } @else {
          -
        }
      </td>
    </tr>
    <tr>
      <td class="font-bold whitespace-nowrap">
        <lib-svg class="flex justify-center fill-[--color-gray]" svg="tv2"></lib-svg>
        {{ ['tv'] | translate }}
      </td>
      <td [innerHTML]="$any(product.prismicTable).tv"></td>
    </tr>
  </tbody>
</table>
