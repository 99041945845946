import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { from, of, switchMap } from 'rxjs';
import { CmsService } from 'prismic';
import { RedirectService } from 'redirect';

export const prismicPreviewGuard: CanActivateFn = route => {
  const platformId = inject(PLATFORM_ID);
  const redirectService = inject(RedirectService);
  const cmsService = inject(CmsService);
  if (isPlatformServer(platformId)) return of(true);

  const previewToken = route.queryParams['token'];
  const documentID = route.queryParams['documentId'];
  return from(
    cmsService.client.resolvePreviewURL({
      documentID,
      previewToken,
      defaultURL: '/',
      linkResolver: doc => `/${cmsService.getLanguageFromLocale(doc.lang)}/${doc.id}`,
    })
  ).pipe(
    switchMap(url => {
      return redirectService.redirectTo(url);
    })
  );
};
