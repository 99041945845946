import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { from, of, switchMap } from 'rxjs';
import { CmsService } from 'prismic';
import { RedirectService } from 'redirect';
import { routeSnapshotToString } from 'utils';
import { ArticleService } from '../article.service';
import { SupportArticle } from '../support-types';

export const articleIdRedirect: CanActivateFn = route => {
  const redirectService = inject(RedirectService);
  const cmsService = inject(CmsService);
  const articleService = inject(ArticleService);
  const path = route.params.article;
  const id = path.slice(0, 16);

  return from(cmsService.getById(id, '*')).pipe(
    switchMap((article: PrismicDocument<SupportArticle>) => articleService.getArticlePath(article)),
    switchMap(newPath => {
      if (newPath === routeSnapshotToString(route)) return of(true);
      return redirectService.redirectTo(newPath);
    })
  );
};
