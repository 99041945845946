@let categories = categories$ | async;

@defer (when categories?.length) {
  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-center gap-10">
    @for (category of categories; track category.id) {
      <lib-action-card
        [link]="transformCardLink(category, languageService.current)"
        [title]="category.data.title"
        [icon]="
          category.data.home_page_icon_key ? 'fontawesome/custom-gradient/' + category.data.home_page_icon_key : null
        "
        class="grid min-h-32 text-center"
      />
    }
  </div>
} @loading (after 100ms; minimum 1000ms) {
  <ngx-skeleton-loader
    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-center gap-10 [&_.skeleton-loader]:!h-28 [&_.skeleton-loader]:!md:h-56"
    count="3"
  />
}
