import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, from, Observable, of, tap } from 'rxjs';
import { BrandService } from 'brand';
import { CmsService, prismicToHtmlString } from 'prismic';
import { MetaService } from 'utils';

export const articleResolver: ResolveFn<Observable<unknown>> = next => {
  const cms = inject(CmsService);
  const meta = inject(MetaService);
  const brand = inject(BrandService);
  const articleId = (next.params.article as string).slice(0, 16);
  return from(cms.getById(articleId, '*')).pipe(
    tap(article => {
      meta.setTitle(`${brand.brand === 'yallo' ? 'yallo' : 'Lebara'} - ${article.data.title}`);
      const articleText = prismicToHtmlString(article.data.content)
        .toString()
        .replace(/<(.|\n)*?>/g, '');
      const concatArticleText = articleText.length > 160 ? articleText.slice(0, 160) + '...' : articleText;
      meta.setDescription(concatArticleText);
    }),
    catchError(() => {
      return of(null);
    })
  );
};
