<div class="bg-color-footer py-4 xl:py-8 flex justify-center">
  <div class="container">
    <div class="flex flex-col items-center justify-center xl:grid xl:grid-cols-3 lg:mx-4">
      <lib-link [link]="goToHomeLink" class="xl:order-2 xl:justify-self-center">
        <lib-svg alt="brand logo" svg="brand/logo" class="primary h-12 logo larger"> </lib-svg>
      </lib-link>
      <div class="flex flex-col items-center xl:items-start xl:order-1">
        <p class="text-sm text-white font-bold mt-3 xl:my-0">{{ slice.company_name }}</p>
        <p class="text-sm text-color-footer">{{ slice.copyright_text }}</p>
      </div>
      <div class="flex flex-col items-center xl:flex-row xl:order-3 xl:justify-self-end">
        @for (section of slice.linkSections; track section) {
          <div class="flex">
            @for (link of section.linkItems; track link) {
              <div class="mx-2">
                <lib-link
                  class="text-sm text-color-footer lg:mt-0 footer__link-text--hover text-center"
                  [link]="link.url"
                >
                  {{ link.title }}
                </lib-link>
              </div>
            }
            <div class="mx-2">
              <div class="text-sm text-color-footer lg:mt-0.5 footer__link-text--hover text-center">
                <a class="cursor-pointer max-w-full link optanon-show-settings">{{
                  ['cookie_settings'] | translate
                }}</a>
              </div>
            </div>
          </div>
        }
        <lib-language-selector></lib-language-selector>
      </div>
    </div>
  </div>
</div>
