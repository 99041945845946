import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductListItem } from 'product';
import { TranslatePipe } from 'translate';
import { CurrencyFormatterPipe } from 'utils';

@Component({
  selector: 'lib-product-box-price',
  standalone: true,
  imports: [CommonModule, CurrencyFormatterPipe, TranslatePipe, MatProgressSpinnerModule],
  templateUrl: './product-box-price.component.html',
  styleUrl: './product-box-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductBoxPriceComponent {
  productItem = input<ProductListItem>();
  hasDiscountedCost = input(false);
  discountedPrice = input<string | number>();
  loadingPrice = input(false);
  product = computed(() => this.productItem().product);
  promotion = computed(() => this.productItem().promotion);
}
