import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { LinkType } from '@prismicio/client';
import { catchError, EMPTY, of } from 'rxjs';
import { AuthService, UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { LanguageService } from 'language';
import { LinkComponent } from 'link';
import { MenuComponent, MenuItemComponent } from 'menu';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-dropdown-menu',
  standalone: true,
  imports: [MenuItemComponent, MenuComponent, CommonModule, SvgComponent, LinkComponent, TranslatePipe],
  templateUrl: './dropdown-menu.component.html',
})
export class DropdownMenuComponent {
  private user = inject(UserService);
  private authService = inject(AuthService);
  lang = inject(LanguageService);
  brand = inject(BrandService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private cdr = inject(ChangeDetectorRef);
  languageService = inject(LanguageService);
  account$ = this.user.getAccount();
  readonly LinkType = LinkType;
  @Input() collapseOnMobile = false;

  logout(): void {
    this.authService
      .logout()
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            console.error(err);
            this.toastService.add(this.translateService.getTranslation(['login', 'error', 'generic']), false);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.account$ = of(null);
        this.cdr.markForCheck();
      });
  }
}
