import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { from, of, switchMap } from 'rxjs';
import { CmsService } from 'prismic';
import { RedirectService } from 'redirect';
import { routeSnapshotToString } from 'utils';
import { CategoryService } from '../category.service';
import { SupportCategory } from '../support-types';

export const categoryIdRedirect: CanActivateFn = route => {
  const redirectService = inject(RedirectService);
  const cmsService = inject(CmsService);
  const categoryService = inject(CategoryService);
  const path = route.params.category;
  const id = path.slice(0, 16);

  return from(cmsService.getById(id, '*')).pipe(
    switchMap((category: PrismicDocument<SupportCategory>) => categoryService.getCategoryPath(category)),
    switchMap(newPath => {
      if (newPath === routeSnapshotToString(route)) return of(true);
      return redirectService.redirectTo(newPath);
    })
  );
};
