import { inject, Injectable } from '@angular/core';
import { PrismicDocument } from '@prismicio/client';
import { Observable, of } from 'rxjs';
import { LanguageService } from 'language';
import { CmsService } from 'prismic';
import { SupportArticle } from './support-types';
import { urlifyTitle } from './urlify-title';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  cmsService = inject(CmsService);
  lang = inject(LanguageService);

  public getArticlePath(article: PrismicDocument<SupportArticle>): Observable<string> {
    if (article.lang !== this.cmsService.languages[this.lang.current]) {
      const correctLangId = article.alternate_languages.find(
        lang => lang.lang === this.cmsService.languages[this.lang.current]
      )?.id;
      return of(`/${this.lang.current}/${correctLangId}`);
    }

    return of(`/${this.lang.current}/${article.id}-${urlifyTitle(article.data.title)}`);
  }
}
