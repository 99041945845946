import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BrandService } from 'brand';
import { LanguageService } from 'language';
import { ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';

interface ApiResponse<T> {
  status: number;
  data: T;
  errorCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RfeCallService {
  private http = inject(HttpClient);
  private languageService = inject(LanguageService);
  private brandService = inject(BrandService);
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);

  async newsletterSubmission(email: string, source: string) {
    const params = { email, brand: this.brandService.brand, source, language: this.languageService.current };

    return await lastValueFrom(
      this.http.post<ApiResponse<Record<string, unknown>>>(
        `${this.config.oldEshopLink}/yalloeshop/subscribe_to_email`,
        params
      )
    );
  }
}
